// import request from "@/utils/request";
import axios from 'axios';
import store from '@/store/index';

// 下载文件
export const downloadFileApi = (url: string) => {
  return axios.get(url, { responseType: 'blob' });
};

// const baseURL =
//   process.env.NODE_ENV == "development"
//     ? // ? "/dev" // 目标测试地址
//       "https://famikeep-test.afirstsoft.cn/api/" // 目标测试地址
//     : "https://my.famikeep.com/api/"; // 目标线上地址

// const baseURL = 'https://my.famikeep.com/api/';
const baseURL = 'https://famikeep-test.afirstsoft.cn/api/';
// const baseURL = "http://192.168.6.26:8000/api/";

// console.log(baseURL, "baseURL");

const service = axios.create({
  baseURL,
  timeout: 10 * 1000,
  headers: {
    // Authorization:
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NjUyMTAyODgsImlhdCI6MTY2MjYxODI4OCwic2Vzc2lvbl9pZCI6IjYzMTk4YWIwZGM4ZTQxYzhmN2ViMDBmYyJ9.WiFuNjdVqy6OENkG0DdpMAZ-dXSO-A3Lba0tKOxMXxY",
    Authorization: localStorage.getItem('authorization'),
  },
});

// 获取聊天会话列表
export const getChatListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const chatListSource = CancelToken.source();
  store.commit('setChatListSource', chatListSource);
  return service({
    url: '/v1/chat-sessions',
    method: 'get',
    params: params,
    cancelToken: chatListSource.token,
  });
};

// 获取联系人列表
export const getContactsListApi = (params: any) => {
  return service({
    url: '/v1/contacts',
    method: 'get',
    params: params,
  });
};

// 获取通讯录列表
export const getPhoneListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const phoneSource = CancelToken.source();
  // console.log(source, "source");

  store.commit('setPhoneSource', phoneSource);
  return service({
    url: '/v1/calls',
    method: 'get',
    params: params,
    cancelToken: phoneSource.token,
  });
};

// 获取聊天记录列表
export const getChatContentListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  // console.log(source, "source");

  store.commit('setSource', source);
  return service({
    url: '/v1/messages',
    method: 'get',
    params: params,
    cancelToken: source.token,
  });
  // .catch((err) => {
  //   // 这里主要是判断这个错误是取消请求导致的还是其他原因导致的
  //   if (axios.isCancel(err)) {
  //     console.log(err.message);
  //   }
  // });
};

// 获取文件下载（在线预览）地址
export const getFileLinkApi = (data: any) => {
  return service({
    url: '/v1/download-url',
    method: 'post',
    data: data,
  });
};

// ios同步获取联系人列表
export const syncContactsListApi = (data: any) => {
  return service({
    url: '/v1/icloud/contacts',
    method: 'post',
    data: data,
  });
};
